import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <p style={{position: "absolute",left: "auto",top: "auto"}}>
          Welcome to kokkurni Home Page
        </p>
      </header>
    </div>
  );
}

export default App;
